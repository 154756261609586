// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require, one-var, no-underscore-dangle */
/* eslint-disable prefer-rest-params, prefer-const, no-shadow, max-len */

import isFunction from 'lodash/isFunction';
import assign from 'lodash/assign';
import once from 'lodash/once';
import uuid from 'uuid';

import serializeMessage from './serializeMessage';
import defaultRumorSocket from './defaultRumorSocket';

export default function RaptorSocketFactory(deps = {}) {
  const convertRumorError = deps.convertRumorError || require('../../helpers/convertRumorError.js');
  const Dispatcher = deps.Dispatcher || require('./Dispatcher.js');
  const ExceptionCodes = deps.ExceptionCodes || require('../../ot/exception_codes.js');
  const hasIceRestartsCapability = deps.hasIceRestartsCapability || require('../../helpers/hasIceRestartsCapability.js');
  const hasRenegotiationCapability = deps.hasRenegotiationCapability || require('../../helpers/hasRenegotiationCapability.js');
  const logging = deps.logging || require('../../helpers/log')('RaptorSocket');
  const RaptorMessage = deps.Message || require('./RaptorMessage.js');
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');
  const RumorSocket = deps.RumorSocket || defaultRumorSocket();
  const Signal = deps.Signal || require('./Signal.js');
  const SignalError = deps.SignalError || require('./SignalError.js');

  // The Dispatcher bit is purely to make testing simpler, it defaults to a new Dispatcher so in
  // normal operation you would omit it.
  const RaptorSocket = function RaptorSocket(
    connectionId,
    widgetId,
    messagingSocketUrl,
    symphonyUrl,
    dispatcher,
    analytics,
    requestedCapabilities = []
  ) {
    let _apiKey,
      _sessionId,
      _token,
      _completion,
      _p2p,
      _messagingServer,
      _rumorErrored;
    const _states = ['disconnected', 'connecting', 'connected', 'error', 'disconnecting'];

    const _dispatcher = dispatcher || new Dispatcher();

    // // Private API
    const setState = OTHelpers.statable(this, _states, 'disconnected');

    const logAnalyticsEvent = function (opt) {
      if (!opt.action || !opt.variation) {
        logging.debug('Expected action and variation');
      }

      analytics.logEvent(assign(
        {
          sessionId: _sessionId,
          partnerId: _apiKey,
          p2p: _p2p,
          messagingServer: _messagingServer,
          connectionId,
        },
        opt
      ));
    };

    const onConnectComplete = (...args) => {
      const [error] = args;

      if (error) {
        setState('error');
      } else {
        setState('connected');
      }

      _completion(...args);
    };

    const onClose = (err) => {
      let reason = 'clientDisconnected';
      if (!this.is('disconnecting') && _rumorErrored) {
        reason = 'networkDisconnected';
      }
      if (err && err.code === 4001) {
        reason = 'networkTimedout';
      }

      setState('disconnected');

      _dispatcher.onClose(reason);
    };

    const onError = (err) => {
      _rumorErrored = true;
      logging.error(err);
    };
    // @todo what does having an error mean? Are they always fatal? Are we disconnected now?

    const onReconnecting = () => {
      _dispatcher.onReconnecting();
    };

    const onReconnected = () => {
      logAnalyticsEvent({
        action: 'Reconnect',
        variation: 'Success',
        retries: this._rumor.reconnectRetriesCount(),
        messageQueueSize: this._rumor.messageQueueSize(),
        socketId: this.socketId,
      });

      _dispatcher.onReconnected();
    };

    const onReconnectAttempt = () => {
      logAnalyticsEvent({
        action: 'Reconnect',
        variation: 'Attempt',
        retries: this._rumor.reconnectRetriesCount(),
        messageQueueSize: this._rumor.messageQueueSize(),
        socketId: this.socketId,
      });
    };

    const onReconnectFailure = (error) => {
      if (error.message === 'connectionLimitExceeded') {
        error.code = ExceptionCodes.CONNECTION_LIMIT_EXCEEDED;
      }
      error.reason = 'ConnectToSession';

      const converted = convertRumorError(error);

      logAnalyticsEvent({
        action: 'Reconnect',
        variation: 'Failure',
        failureReason: error.reason,
        failureCode: converted.code,
        failureMessage: converted.message,
        messageQueueSize: this._rumor.messageQueueSize(),
        socketId: this.socketId,
      });
    };

    // // Public API

    Object.defineProperty(this, 'socketId', {
      get() {
        return this._rumor.socketID;
      },
    });

    this.connect = function connect(token, sessionInfo, opt, completion) {
      if (!this.is('disconnected', 'error')) {
        logging.warn('Cannot connect the Raptor Socket as it is currently connected. You should ' +
          'disconnect first.');
        return;
      }

      setState('connecting');
      _apiKey = sessionInfo.partnerId;
      _sessionId = sessionInfo.sessionId;
      _p2p = sessionInfo.p2pEnabled;
      _messagingServer = sessionInfo.messagingServer;
      _token = token;
      _completion = completion;

      const rumorChannel = `/v2/partner/${_apiKey}/session/${_sessionId}`;
      this._rumor = new RaptorSocket.RumorSocket({
        messagingURL: messagingSocketUrl,
        notifyDisconnectAddress: symphonyUrl,
        connectionId,
        enableReconnection: sessionInfo.reconnection,
      });

      this._rumor.on('close', onClose);
      this._rumor.on('error', onError);
      this._rumor.on('reconnecting', onReconnecting);
      this._rumor.on('reconnectAttempt', onReconnectAttempt);
      this._rumor.on('reconnectFailure', onReconnectFailure);
      this._rumor.on('reconnected', onReconnected);
      this._rumor.on('message', _dispatcher.dispatch.bind(_dispatcher));

      const onStartupError = (error) => {
        onConnectComplete({
          reason: 'WebSocketConnection',
          code: error.code,
          message: error.message,
        });
      };

      this._rumor.once('error', onStartupError);

      this._rumor.once('open', () => {
        this._rumor.removeListener('error', onStartupError);

        logging.debug(`connected. Subscribing to ${
          rumorChannel} on ${messagingSocketUrl}`);

        this._rumor.subscribe([rumorChannel]);

        const capabilities = requestedCapabilities;

        const supportRenegotiation = (
          RaptorSocket.hasIceRestartsCapability() ||
          RaptorSocket.hasRenegotiationCapability()
        ) && sessionInfo.renegotiation;

        if (supportRenegotiation) {
          capabilities.push('renegotiation');
        }

        // connect to session
        const connectMessage = RaptorMessage.connections.create({
          apiKey: _apiKey,
          sessionId: _sessionId,
          connectionId: this._rumor.id,
          connectionEventsSuppressed: opt.connectionEventsSuppressed,
          capabilities,
        });

        const futureOwnConnection = new Promise((resolve) => {
          const processConnectionCreated = (message) => {
            if (message.id === this._rumor.id) {
              resolve(message);
              dispatcher.off('connection#created', processConnectionCreated);
            }
          };
          dispatcher.on('connection#created', processConnectionCreated);
        });

        this.publish(connectMessage, { 'X-TB-TOKEN-AUTH': _token }, true, (error, reply) => {
          if (error) {
            if (error.message === 'connectionLimitExceeded') {
              error.code = ExceptionCodes.CONNECTION_LIMIT_EXCEEDED;
            }
            onConnectComplete({
              reason: 'ConnectToSession',
              code: error.code,
              message: error.message,
              socketId: this.socketId,
            });
            return;
          }

          const replyData = reply && reply.data ? JSON.parse(reply.data) : null;

          if (replyData) {
            if (!replyData.connection || replyData.connection.length === 0) {
              replyData.connection = [];
              if (Array.isArray(replyData.stream)) {
                replyData.stream.forEach(({ connection }) => replyData.connection.push(connection));
              }
            }
          }

          const onSessionState = (error, sessionState) => {
            if (error) {
              onConnectComplete({
                reason: 'GetSessionState',
                code: error.code,
                message: error.message,
                socketId: this.socketId,
              });
            } else {
              onConnectComplete(undefined, sessionState);
            }
          };

          futureOwnConnection.then((ownConnection) => {
            // in the case of not receiving our own connection, we add it here.
            // this is important under connectionEventSuppressed as rumor does
            // not give us our own connection in the initial ack to our connect
            // message
            if (!replyData.connection.some(conn => conn.id === ownConnection.id)) {
              replyData.connection.unshift(ownConnection);
            }
            const transactionId = uuid();
            _dispatcher.registerCallback(transactionId, onSessionState);
            _dispatcher.emit('session#read', replyData, transactionId);
          });
        });
      });
    };

    this.disconnect = function () {
      if (this.is('disconnected')) {
        return;
      }

      setState('disconnecting');
      this._rumor.disconnect();
    };

    // Publishes +message+ to the Symphony app server.
    //
    // The completion handler is optional, as is the headers
    // dict, but if you provide the completion handler it must
    // be the last argument.
    //
    this.publish = function (message, headers, retryAfterReconnect, completion) {
      completion = completion || function () {};
      const completionOnce = once(completion);

      const transactionId = uuid();

      logging.debug(`Publish (ID:${transactionId}) ${message}`);

      if (
        this._rumor.readyState !== RumorSocket.OPEN ||
        (this._rumor.reconnecting && !retryAfterReconnect)
      ) {
        // TODO: This duplicates the same logic in rumor.
        const error = new Error('Not connected.');
        error.code = 500;
        completionOnce(error);
        logging.error('cannot publish until the socket is connected.');

        return undefined;
      }

      _dispatcher.registerCallback(transactionId, completionOnce);

      this._rumor.publish(
        [symphonyUrl],
        message,
        assign({}, headers, {
          'Content-Type': 'application/x-raptor+v2',
          'TRANSACTION-ID': transactionId,
          'X-TB-FROM-ADDRESS': this._rumor.id,
        }),
        retryAfterReconnect,
        function (err) {
          // We want to propagate errors from rumor here. In particular, errors
          // related to not receiving a reply due to disconnection. However, when
          // a reply is received, the dispatcher may transform the reply, and may
          // generate an error that would not be recognized here. This isn't the
          // only awkward outcome related to the dispatcher design, and there are
          // plans to address this technical debt: OPENTOK-27994.
          if (err) {
            completionOnce(...arguments);
          }
        }
      );

      return transactionId;
    };

    /**
     * Like publish, but automaitcally serializes the message parameter
     */
    this.send = (message, headers, retryAfterReconnect = true, completion = () => {}) => {
      this.publish(serializeMessage(message), headers, retryAfterReconnect, completion);
    };

    // Register a new stream against _sessionId
    this.streamCreate = function (name, streamId, audioFallbackEnabled, channels, minBitrate,
      maxBitrate, sourceStreamId, completion) {
      const message = RaptorMessage.streams.create(_apiKey,
        _sessionId,
        streamId,
        name,
        audioFallbackEnabled,
        channels,
        minBitrate,
        maxBitrate,
        sourceStreamId);

      this.publish(message, {}, true, (error, message) => {
        completion(error, streamId, message);
      });
    };

    this.streamDestroy = function (streamId, sourceStreamId) {
      this.publish(RaptorMessage.streams.destroy(_apiKey, _sessionId,
        streamId, sourceStreamId), {}, true);
    };

    this.streamChannelUpdate = function (streamId, channelId, attributes) {
      this.publish(RaptorMessage.streamChannels.update(_apiKey, _sessionId,
        streamId, channelId, attributes), {}, true);
    };

    this.subscriberCreate = function (streamId, subscriberId, channelsToSubscribeTo, sourceStreamId,
      completion) {
      this.publish(RaptorMessage.subscribers.create(_apiKey, _sessionId,
        streamId, subscriberId, this._rumor.id, channelsToSubscribeTo, sourceStreamId),
      {}, true, completion);
    };

    this.subscriberDestroy = function (streamId, subscriberId) {
      this.publish(RaptorMessage.subscribers.destroy(_apiKey, _sessionId,
        streamId, subscriberId), {}, true);
    };

    this.subscriberUpdate = function (streamId, subscriberId, attributes) {
      this.publish(RaptorMessage.subscribers.update(_apiKey, _sessionId,
        streamId, subscriberId, attributes), {}, true);
    };

    this.subscriberChannelUpdate = function (streamId, subscriberId, channelId, attributes) {
      this.publish(RaptorMessage.subscriberChannels.update(_apiKey, _sessionId,
        streamId, subscriberId, channelId, attributes), {}, true);
    };

    this.forceDisconnect = function (connectionIdToDisconnect, completion) {
      this.publish(
        RaptorMessage.connections.destroy({
          apiKey: _apiKey,
          sessionId: _sessionId,
          connectionId: connectionIdToDisconnect,
        }),
        {},
        true,
        completion
      );
    };

    this.forceUnpublish = function (streamIdToUnpublish, completion) {
      this.publish(RaptorMessage.streams.destroy(_apiKey, _sessionId,
        streamIdToUnpublish), {}, true, completion);
    };

    this.forceMuteStream = function (streamIdToMute, active, completion) {
      this.publish(RaptorMessage.forceMute.update(_apiKey, _sessionId,
        streamIdToMute, active), {}, true, completion);
    };

    this.forceMuteAll = function (excludedStreamIds, active, completion) {
      this.publish(RaptorMessage.forceMute.update(_apiKey, _sessionId,
        null, active, excludedStreamIds), {}, true, completion);
    };

    this.signal = function (options, completion, logEventFn) {
      const signal = new Signal(_sessionId, this._rumor.id, options || {});

      if (!signal.valid) {
        if (completion && isFunction(completion)) {
          completion(new SignalError(signal.error.code, signal.error.reason), signal.toHash());
        }

        return;
      }

      this.publish(signal.toRaptorMessage(), {}, signal.retryAfterReconnect, (err) => {
        let error,
          errorCode,
          errorMessage;
        const expectedErrorCodes = [400, 403, 404, 413, 500];

        if (err) {
          const parsedErrorCode = parseInt(err.code, 10);
          if (expectedErrorCodes.includes(parsedErrorCode)) {
            errorCode = parsedErrorCode;
            errorMessage = err.message;
          } else {
            errorCode = ExceptionCodes.UNEXPECTED_SERVER_RESPONSE;
            errorMessage = 'Unexpected server response. Try this operation again later.';
          }
          error = new SignalError(errorCode, errorMessage);
        } else {
          const typeStr = signal.data ? typeof (signal.data) : null;
          logEventFn('signal', 'send', { type: typeStr });
        }

        if (completion && isFunction(completion)) { completion(error, signal.toHash()); }
      });
    };

    this.id = function () {
      return this._rumor && this._rumor.id;
    };
  };

  RaptorSocket.hasIceRestartsCapability = hasIceRestartsCapability;
  RaptorSocket.hasRenegotiationCapability = hasRenegotiationCapability;
  RaptorSocket.RumorSocket = RumorSocket;

  return RaptorSocket;
}
